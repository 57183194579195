<script>
require("bootstrap");

export default {
  name: "BillingFilter",
  data() {
    return {
      showFilters: false,
      parameter: "",
      parameter_opts: [
        {
          name: "Artist",
          value: "invoice__schedule__group__name__icontains",
          only_equals_comparison: 1,
          replacement_text: "Contains",
        },
        {
          name: "Location",
          value: "invoice__schedule__space_location__name__icontains",
          only_equals_comparison: 1,
          replacement_text: "Is",
        },
        {
          name: "Due Amount",
          value: "invoice__due_amount",
          replacement_text: "is",
        },
        {
          name: "Payment Method",
          value: "payment_method",
          only_equals_comparison: 1,
        },
        {
          name: "Start Time",
          value: "invoice__schedule__start_time",
          no_equals: 1,
          placeholder: "dd/mm/yyyy",
        },
        {
          name: "End Time",
          value: "invoice__schedule__end_time",
          no_equals: 1,
          placeholder: "dd/mm/yyyy",
        },
      ],
      comparison: "=",
      value: "",
      disable_comp: 0,
      disable_equals: 0,
      replacement_text: "",
      filters: [],
      placeholder: "",
    };
  },
  methods: {
    paramChange() {
      if (this.parameter) {
        this.placeholder = this.parameter.placeholder;
        if (this.parameter.only_equals_comparison) {
          this.disable_comp = 1;
          this.comparison = "=";
        } else {
          this.disable_comp = 0;
        }

        if (this.parameter.no_equals) {
          this.disable_equals = 1;
          this.equals = ">";
        } else {
          this.disable_equals = 0;
        }

        if (this.parameter.replacement_text) {
          this.replacement_text = this.parameter.replacement_text;
        } else {
          this.replacement_text = "";
        }
      } else {
        this.disable_comp = 0;
        this.disable_equals = 0;
        this.placeholder = "";
        this.replacement_text = "";
      }
    },
    addFilter() {
      if (!this.parameter) {
        alert("Field needed.");
        return;
      }

      if (!this.value) {
        alert("Value needed.");
        return;
      }

      if (
        this.parameter.name === "Start Time" ||
        this.parameter.name === "End Time"
      ) {
        var pattern = /\d{2}\/\d{2}\/\d{4}/;
        if (!pattern.test(this.value)) {
          alert(
            "Invalid Date Format. Please use format 08/12/2019 for example."
          );
          return;
        }
      }

      this.filters.push({
        parameter: this.parameter,
        comparison: this.comparison,
        value: this.value,
      });

      this.parameter = "";
      this.comparison = "=";
      this.value = "";
      this.search();
    },
    search() {
      this.$emit("applyFilters", this.filters);
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
      this.search();
    },
  },
  watch: {
    filters() {
      if (this.filters.length === 0) {
        this.$emit("applyFilters", null);
      }
    },
  },
};
</script>

<template>
  <div class="accordion" id="BillingFilter">
    <div class="card">
      <div class="card-header" id="headingOne">
        <div class="mb-0">
          <button
            id="FilterButton"
            class="btn btn-link collapsed"
            type="button"
            @click.prevent="showFilters = !showFilters"
          >
            Filters (click to show/collapse)
          </button>
        </div>
      </div>
      <div
        v-if="showFilters"
        aria-labelledby="headingOne"
        data-parent="#BillingFilter"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              <select
                class="form-control"
                v-model="parameter"
                @change="paramChange"
              >
                <option value="">---Field---</option>
                <template v-for="opt in parameter_opts">
                  <option :value="opt" :key="opt.name">{{ opt.name }}</option>
                </template>
              </select>
            </div>

            <div class="col-4 col-md-2">
              <select
                class="form-control"
                :disabled="disable_comp === '1'"
                v-model="comparison"
              >
                <option value="=" v-if="!disable_equals">
                  <template v-if="replacement_text">
                    {{ replacement_text }}
                  </template>
                  <template v-else> = (equal to) </template>
                </option>
                <option value=">">&gt; (greater than)</option>
                <option value="<">&lt; (less than)</option>
              </select>
            </div>

            <div class="col-4">
              <div class="row">
                <div class="col-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="value"
                    :placeholder="placeholder"
                  />
                </div>
                <div class="col-4">
                  <button class="btn btn-primary" @click="addFilter">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id="filters" class="px-2">
            <div class="col mb-1" v-if="!filters.length">
              <button class="btn">--- No Filters---</button>
            </div>
            <div v-else class="col mb-1">
              <button class="btn btn-success" @click="search">Search</button>
            </div>
            <div class="col order-2">
              <div
                class="filter vertical-align-middle"
                v-for="(filter, index) in filters"
                :key="index"
              >
                {{ filter.parameter.name }}{{ filter.comparison }}'{{
                  filter.value
                }}'
                <div class="close">
                  <i class="fas fa-times" @click="removeFilter(index)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#BillingFilter {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 14px;
}
#filters {
  background: #eee;
  min-height: 25px;
  border: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  .filter {
    display: inline-block;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 2px 3px;
    margin-right: 3px;
    .close {
      display: inline-block;
      visibility: hidden;
      font-size: 16px;
      padding-top: 2px;
      padding-bottom: 2px;
      margin-left: 3px;
      margin-bottom: 3px;
      cursor: pointer;
    }
    &:hover {
      .close {
        visibility: visible;
      }
    }
  }
}
</style>
