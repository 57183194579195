<script>
import api from "@/api";

export default {
  name: "BillingTable",
  props: ["payments", "page", "unpaid_table", "has_more"],
  data() {
    return {
      invoice_amount: 0,
      invoice_schedule_id: null,
      invoice_payment_method: "Cash",
      invoice_id: null,
      total: 0,
      showInvoiceModal: false,
    };
  },
  methods: {
    onP() {
      this.$emit("onPrevious");
    },
    onN() {
      this.$emit("onNext");
    },
    getInvoice(payment) {
      if (payment.invoice) {
        return payment.invoice;
      }
      return payment;
    },
    getTime(time) {
      var t = time.split("T")[1];
      var parts = t.split(":");
      return parts[0] + ":" + parts[1];
    },
    saveInvoice() {
      var inv = {
        invoice_id: this.invoice_id,
        amount: this.invoice_amount,
        payment_method: this.invoice_payment_method,
      };
      const url = `/schedules/${this.invoice_schedule_id}/new_payment/`;
      api.Util.axios.post(url, inv).then(() => {
        this.invoice_id = null;
        this.invoice_amount = 0;
        this.invoice_schedule_id = 0;
        this.$emit("reloadData");
        this.showInvoiceModal = false;
      });
    },
    showInvModal(invoice) {
      this.invoice_id = invoice.id;
      this.invoice_amount = invoice.total;
      this.invoice_schedule_id = invoice.schedule.id;
      this.showInvoiceModal = true;
    },
  },
  computed: {
    getTotal() {
      var total = 0;
      for (var i = 0; i < this.payments.length; i++) {
        if (this.unpaid_table) {
          total += parseInt(this.payments[i].total);
        } else {
          total += parseInt(this.payments[i].amount);
        }
      }
      return total;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="modal"
      id="invoice_modal"
      tabindex="-1"
      role="dialog"
      v-if="showInvoiceModal"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <form>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Amount:</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="invoice_amount"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Payment Method</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    v-model="invoice_payment_method"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click.prevent="showInvoiceModal = false"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="saveInvoice"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <template v-if="payments.length">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <th>Artist</th>
            <th>Location</th>
            <th>Amount</th>
            <th v-if="!unpaid_table">Payment Method</th>
            <th>Times</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="payment in payments" :key="payment.id">
              <td>
                <router-link
                  :to="{
                    name: 'ScheduleItem',
                    params: { ID: getInvoice(payment).schedule.id },
                  }"
                >
                  {{ getInvoice(payment).schedule.name }}
                </router-link>
              </td>
              <td>
                <template v-if="getInvoice(payment).schedule.space_location">
                  {{ getInvoice(payment).schedule.space_location.name }}
                </template>
              </td>
              <td>
                Ksh.
                <span v-if="unpaid_table">{{ payment.total }}</span>
                <span v-else>{{ payment.amount }}</span>
                /=
              </td>

              <td v-if="!unpaid_table">{{ payment.payment_method }}</td>
              <td>
                <div>{{ getInvoice(payment).schedule.date_readable }}</div>
                <br />
                <span>{{
                  getTime(getInvoice(payment).schedule.start_time)
                }}</span>
                -
                <span>{{
                  getTime(getInvoice(payment).schedule.end_time)
                }}</span>
              </td>
              <td>
                <template v-if="unpaid_table">
                  <a class="text-success mb-1" target="blank_">
                    <!-- <a :href="'/invoice/'+payment.id" class="text-success" target="blank_"> -->
                    <s>Show Invoice</s>
                    <i
                      class="fas fa-file-invoice-dollar text-success"
                      title="Print Invoice"
                    ></i>
                  </a>
                  <div>
                    <button
                      class="btn btn-primary"
                      @click="showInvModal(payment)"
                    >
                      Update Payment
                    </button>
                  </div>
                </template>
                <template v-else>
                  <a class="text-success" target="blank_">
                    <!-- <a :href="'/receipt/'+payment.id" class="text-success" target="blank_"> -->
                    <s
                      >Show Receipt
                      <i
                        class="fas fa-file-invoice-dollar text-success"
                        title="Print Reciept"
                      ></i
                    ></s>
                  </a>
                </template>
              </td>
            </tr>
            <tr>
              <td><strong>Total</strong></td>
              <td></td>
              <td>Ksh. {{ getTotal }} /=</td>
              <td colspan="20"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-else>
      <div class="col-12 text-center" id="nopay">No Payments Found.</div>
    </template>

    <div class="row justify-content-center" id="pagination">
      <div class="col col-md-2">
        <button
          v-show="page > 1"
          class="rounded border border-danger py-2 px-2 mr-2 btn-danger"
          @click="onP"
        >
          <i class="fas fa-chevron-circle-left"></i>
          Page {{ page - 1 }}
        </button>
      </div>
      <div class="col col-md-2">
        <button class="rounded border border-light py-2 px-2 mr-2 disabled">
          Page {{ page }}
        </button>
      </div>
      <div class="col col-md-2">
        <button
          v-show="payments.length != 0 && has_more"
          class="rounded border border-success py-2 px-2 mr-1 btn-success"
          @click="onN"
        >
          Page {{ page + 1 }}
          <i class="fas fa-chevron-circle-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    margin-right: 20px;
  }
}
#invoice_modal {
  display: block;
}
</style>
